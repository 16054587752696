import subsitesEventLogging from './logging-context';

window.addEventListener('load', async () => {
    const isHomepage = location.pathname === '/';

    const connectionInfo = {
        CLIENT_ID: atob(EVENT_LOG_CLIENT_ID + '=='),
        URL: 'https://api.pinterest.com/v3/coc_event/',
        APP_CLIENT_SECRET: atob(EVENT_LOG_CLIENT_SECRET + '=='),
        COUNTRY: getCountryCode(),
        APP_NAME: EVENT_LOG_APP_NAME,
    };

    // Log a page view event
    const eventData = {
        eventName: 'VIEW',
        viewType: 'PAGE',
        componentData: {
            entity_id: 'PAGE_VIEW',
            source_title: document.title,
            viewData: {
                entity_type: document.title,
                entity_type: isHomepage ? 'HOMEPAGE' : 'PAGE',
                entity_id: location.href,
            },
        },
    };

    // Send the event log
    await subsitesEventLogging.logEvent(connectionInfo, eventData);
});

function getCountryCode() {
    // Check if the  cookie is available
    const countryCodeCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('_ss_country_code='));
    if (countryCodeCookie) {
        return countryCodeCookie.split('=')[1];
    }

    // Fallback to using the browser's language setting
    const language = navigator.language || navigator.userLanguage;
    const countryCode = language.split('-')[1];
    return countryCode ? countryCode.toUpperCase() : 'US'; // Default to 'US' if no country code is found
}
