import "../css/style.css";
import "./logging-events";
import nav from "./nav";
import localeSwitcher from "./locale-switcher";
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

const loadEvent = import.meta.env.VITE_STATIC ?? false ? "static-load" : "load";
window.addEventListener(loadEvent, () => {
    nav();
    localeSwitcher();

    window.Alpine = Alpine;

    Alpine.plugin(focus);
    Alpine.start();
})

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
